import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { doAuthenticatedPut } from '../../../../../../../actions/_methods';
import { API_TASK_UPDATE_CHECKLIST } from '../../../../../../../const/api_paths';
import { useSnackbar } from 'notistack';
import { useTask } from '../../../../../../context/task';

const useStyles = makeStyles((theme) => ({
  item: {
    paddingTop: '0',
    paddingBottom: '0',
    marginTop: '0',
    marginBottom: '0',
  },
  item_done: {
    textDecoration: 'line-through',
  },
  list_item: {
    paddingTop: '1px',
  },
  check_icon: {
    fontSize: 'medium',
    color: 'green',
    minWidth: '28px',
    padding: '0',
  },
}));

const name = 'checklist';
const title = 'Checklist';

export default ({ row }) => {
  const classes = useStyles();
  const [status, setStatus] = useState(row.status);
  const { setComments } = useTask();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateChecklist = (new_stats) => {
    let api_path = API_TASK_UPDATE_CHECKLIST.replace(
      ':task_id',
      row.task_id
    ).replace(':id', row.id);

    doAuthenticatedPut(
      api_path,
      { status: new_stats },
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const onChange = (e) => {
    let new_stats = !status;
    updateChecklist(new_stats);
    setStatus(new_stats);
  };

  const onSuccess = (res) => {
    setComments(res.comments);

    enqueueSnackbar('You have successfully UPDATED task checklist.', {
      variant: 'success',
    });
  };

  const onError = (res) => {
    enqueueSnackbar('An ERROR occured, please try again.', {
      variant: 'error',
    });
  };

  const Row = () => {
    return (
      <>
        <ListItem key={row.id} className={classes.list_item}>
          <ListItemButton className={classes.item} onClick={onChange}>
            <ListItemIcon className={classes.check_icon}>
              <Checkbox
                color="success"
                edge="start"
                className={classes.check_icon}
                checked={status}
              />
            </ListItemIcon>
            <ListItemText
              className={`${classes.item} ${status ? classes.item_done : ''}`}
              primary={row.name}
            />
          </ListItemButton>
        </ListItem>
      </>
    );
  };

  return <Row />;
};
