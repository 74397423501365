import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Card, CardContent, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import Name from './info/Name';
import AccountLocation from './info/AccountLocation';
import StartDate from './info/StartDate';
import DueDate from './info/DueDate';
import TaskCategory from "./info/TaskCategory";
import AssignedTo from './info/AssignedTo';
import Tags from './info/Tags';
import Description from './info/Description';
import File from './info/File';
import CheckList from './info/Checklist';
import { usePermission } from '../../../../../context/permission';
import { IfAnyGranted } from 'react-authorization';
import { useGlobal } from '../../../../../context/global';
import { useFirmTasks } from '../../../../../context/firm_tasks';
import {
  doAuthenticatedPut,
  doAuthenticatedDelete,
} from '../../../../../../actions/_methods';
import { API_TASKS } from '../../../../../../const/api_paths';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '65%',
    marginBottom: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  inner_box: {
    display: 'flex',
    marginTop: '25px',
  },
  buttons_box: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel-btn': {
      marginLeft: '15px',
    },
    '& .delete-btn': {
      marginLeft: '15px',
    },
  },
}));

export default ({ task, onTaskUpdate, onTaskDelete, onCancelEdit }) => {
  const classes = useStyles();
  const { atleastAdmin, atleastSuperAngel, getMyRoles } = usePermission();
  const { firmUsers, getSelectedFirm } = useGlobal()
  const [data, setData] = useState({});
  const [orig_data, setOrigData] = useState({});
  const read_mode = false;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { preferences } = useFirmTasks();
  const firmData = getSelectedFirm();
  const firmTags = firmData.tags;

  const allowAddName =
    preferences.firm_v2 === 'false' &&
    !Boolean(task.project_task_id) &&
    !Boolean(task.accounting_project_task_id);
  const showTaskCategoryFields = !task.accounting_project_task_id
  const showStartDate = firmTags.includes("Tech");

  const sanitizeTask = (task_data) => {
    var new_task = { ...task_data };

    const levels = [1, 2, 3];
    levels.forEach((i) => {
      if (task_data.organizational_structure[i - 1]) {
        new_task[`level_${i}`] = task_data.organizational_structure[i - 1];
      }
    });

    return new_task;
  };

  const onChange = (new_data) => {
    setData({ ...data, ...new_data });
  };

  const onCancelUpdateTask = () => {
    setData({ ...orig_data });
    onCancelEdit();
  };

  const onClickUpdateTask = () => {
    doAuthenticatedPut(`${API_TASKS}/${data.id}`, data, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  };

  const onClickDeleteTask = () => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      doAuthenticatedDelete(`${API_TASKS}/${data.id}`, data, {
        success: onSuccessDelete,
        general: onSuccessDelete,
        error: onError,
      });
    }
  };

  const onSuccess = (res) => {
    enqueueSnackbar('You have successfully UPDATED the task.', {
      variant: 'success',
    });
    onTaskUpdate(res.task);
  };

  const onError = (res) => {
    let errorMessage = "Unable to update the task:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  };

  const onSuccessDelete = (res) => {
    enqueueSnackbar('You have successfully DELETED the task.', {
      variant: 'info',
    });
    onTaskDelete(res.task);
  };

  useEffect(() => {
    const sanitized_task = sanitizeTask(task);
    setData(sanitized_task);
    setOrigData(sanitized_task);
  }, [task]);

  return (
    <Card className={classes.box}>
      <CardContent>
        { allowAddName && <Box>
          <Name
            default_value={task.name}
            read_mode={read_mode}
            onChange={onChange}
          />
        </Box>
        }
        <Box className={classes.inner_box}>
          <AccountLocation
            read_mode={read_mode}
            default_level_1={data.level_2 && data.level_2.id}
            default_level_2={data.level_3 && data.level_3.id}
            onChange={onChange}
            firm_id={task.firm_id}
          />
        </Box>

        <Box className={classes.inner_box}>
          <div className={classes.wrapper}>
            {showStartDate && (
              <StartDate
                read_mode={read_mode}
                default_value={task.start_date}
                onChange={onChange}
              />
            )}
            <DueDate
              read_mode={read_mode}
              default_value={task.due_date}
              onChange={onChange}
            />
            <AssignedTo
              read_mode={read_mode}
              default_value={data.assigned_to}
              onChange={onChange}
              options={firmUsers}
            />
          </div>
        </Box>

        {showTaskCategoryFields && (
          <IfAnyGranted expected={atleastAdmin()} actual={getMyRoles()}>
            <Box className={classes.inner_box}>
              <TaskCategory onChange={onChange} required={true} defaultValue={task.task_category_id} />
            </Box>
          </IfAnyGranted>
        )}

        <Box className={classes.inner_box}>
          <Tags
            read_mode={read_mode}
            default_value={task.tags}
            onChange={onChange}
          />
        </Box>

        <Box className={classes.inner_box}>
          <CheckList task={task} default_value={task.checklist} />
        </Box>

        <Box className={classes.inner_box}>
          <Description
            read_mode={read_mode}
            default_value={task.description}
            onChange={onChange}
          />
        </Box>

        <Box className={classes.inner_box}>
          <File read_mode={read_mode} task={task} default_files={task.files} />
        </Box>

        <Box className={classes.buttons_box}>
          <Button
            variant="contained"
            color="primary"
            disabled={false}
            onClick={onClickUpdateTask}
          >
            Update Task
          </Button>

          <IfAnyGranted expected={atleastSuperAngel()} actual={getMyRoles()}>
            <Button
              variant="contained"
              className="delete-btn"
              color="secondary"
              onClick={onClickDeleteTask}
            >
              Delete Task
            </Button>
          </IfAnyGranted>

          <Button
            variant="contained"
            className="cancel-btn"
            onClick={onCancelUpdateTask}
          >
            Cancel
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
