import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Pagination, PaginationItem } from "@mui/material";
import {
  Box,
  Button,
  Stack,
  CardContent,
  Card,
  CardHeader,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";

import { SHIFT_OPTIONS } from "../../board/const/ref";
import { doAuthenticatedGet } from "../../../actions/_methods";
import { API_PATH, USER_DATA_REPORTS } from "../../../const/api_paths";
import InputPeople from "../../board/components/_basic_filters/InputPeople";
import DateRange from "../../board/components/_basic_filters/DateRange";
import InputSelect from "../../board/components/_basic_filters/InputSelect";
import { useReferences } from "../../context/references";

import Organizational from "./components/_Organizational";
import Results from "./components/_Results";
import TaskCategory from "./components/_TaskCategory";
import Presets from "./components/_Presets";

const useStyles = makeStyles((_theme) => ({
  card: {
    marginTop: "10px",
    border: "1px solid #9f9f9f",
  },
  presetButtons: {
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "flex-start",
  },
  actionButtons: {
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
}));

export default () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    connected_users: user_options,
    organizations,
    loadConnectedUsers,
    loadOrganizations,
    loadTaskCategories,
    loaded_connected_users,
    loaded_organizations,
    myself,
  } = useReferences();

  const calendarDefault = {
    startDate: moment().startOf("week").toDate(),
    endDate: moment().endOf("week").toDate(),
  };
  const defaultFilters = {
    start_date: moment().startOf("week").format("YYYY-MM-DD"),
    end_date: moment().endOf("week").format("YYYY-MM-DD"),
  };
  const [results, setResults] = useState([]);
  const [date_range_str, setDateRangeStr] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [fieldValues, setFieldValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const [page, setPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [disableGenerateReport, setDisableGenerateReport] = useState(false);
  const sourceType = "tasks"; // user_tasks or tasks

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    loadConnectedUsers();
    loadOrganizations();
    loadTaskCategories();

    // Load the initial data
    setFilters({ ...filters, timezone: tz });
    // handleFilter(); Save bandwidth by not loading the first time
  }, []);

  const doFilter = (page) => {
    setLoading(true);
    setShowInitialMessage(false);
    var target_url = `${USER_DATA_REPORTS}?source=${sourceType}&page=${page}&${dataToParams()}`;
    doAuthenticatedGet(
      target_url,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const handleFilter = () => {
    setPage(1);
    doFilter(1);
  };

  const onSuccess = (res) => {
    setDisableGenerateReport(false);
    setLoading(false);
    setResults(res["rows"]);
    setDateRangeStr(res["date_range_readable"]);
    setTotalPages(res["total_pages"]);
  };

  const onError = (res) => {
    let errorMessage = "Unable to fetch the report:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
    setDisableGenerateReport(true);
    setLoading(false);
  };

  const onPaginate = (event, value) => {
    setPage(value);
    doFilter(value);
  };

  const handleGenerateReport = () => {
    console.log("Generate Report", filters);

    const encoded = window.btoa(myself.id);
    const target_url = API_PATH(
      `/reports/user_data.csv?source=${sourceType}&uid=${encoded}&${dataToParams()}`
    );
    console.log(target_url);
    window.location = target_url;
  };

  const dataToParams = () => {
    const new_data = { ...filters };
    Object.keys(new_data).forEach((key) => {
      if (new_data[key] === null) {
        delete new_data[key];
      }
    });

    return new URLSearchParams(new_data).toString();
  };

  const handleChange = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeOrganization = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeTaskCategory = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeDateRange = (selection) => {
    var newFilters = {
      ...filters,
      ...{
        start_date: moment(selection.startDate).format("YYYY-MM-DD"),
        end_date: moment(selection.endDate).format("YYYY-MM-DD"),
      },
    };
    setFilters(newFilters);
  };

  const setFiltersFromPreset = (presetFilters) => {
    console.log("Setting filters from preset", presetFilters);
    setFilters({
      ...presetFilters,
      start_date: filters.start_date,
      end_date: filters.end_date,
    });
    setFieldValues({
      user_id: user_options.filter((u) =>
        presetFilters.user_id?.includes(u.id)
      ),
      organizations: {
        firmIds: presetFilters.firm_ids,
        accountId: presetFilters.account_id,
        locationId: presetFilters.location_id,
      },
      user_shift: presetFilters.user_shift,
      categories: {
        parentId: presetFilters.parent_task_category_id,
        id: presetFilters.task_category_id,
      },
    });
  };

  return (
    <Card className={classes.card}>
      <CardHeader title="User Weekly Report v2" />
      <CardContent>
        <Box>
          <Stack direction="row" spacing={2}>
            <InputPeople
              name="user_id"
              title="Person"
              options={user_options}
              onChange={handleChange}
              presetValue={fieldValues.user_id}
            />
            <Box display="inline-flex" justifyContent="space-between">
              <Organizational
                onChange={onChangeOrganization}
                options={organizations}
                presetValue={fieldValues.organizations}
              />
            </Box>
            <InputSelect
              width={230}
              name="user_shift"
              title="Shift"
              options={SHIFT_OPTIONS}
              onChange={handleChange}
              default_value={""}
              presetValue={fieldValues.user_shift}
              changedAt={filters.changedAt}
            />
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TaskCategory
              onChange={onChangeTaskCategory}
              presetValue={fieldValues.categories}
            />
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <DateRange
              start_date={calendarDefault.startDate}
              end_date={calendarDefault.endDate}
              onChange={onChangeDateRange}
            />
          </Stack>
          <Stack direction="row" my={2}>
            <Stack
              direction="row"
              spacing={2}
              width="50%"
              className={classes.presetButtons}
            >
              <Presets
                filters={filters}
                setFilters={setFiltersFromPreset}
                enable={loaded_connected_users && loaded_organizations}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              width="50%"
              className={classes.actionButtons}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilter}
              >
                Filter
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateReport}
                disabled={disableGenerateReport}
              >
                Download Report
              </Button>
            </Stack>
          </Stack>
        </Box>
        {loading && <Stack mt={2}>Loading... </Stack>}
        {!loading && (
          <Results
            results={results}
            date_range_str={date_range_str}
            showInitialMessage={showInitialMessage}
          />
        )}
        <Stack mt={2}>
          <Pagination
            count={total_pages}
            page={page}
            onChange={onPaginate}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
            renderItem={(item) => {
              // Hide the last page item
              if (item.type === "page" && item.page === total_pages) {
                return null;
              }
              return <PaginationItem {...item} />;
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
